$titleWidth: 18rem;
$rowsMargin: 2.1rem;

.settings-table {
    &__header {
        display: flex;
        margin-left: calc($titleWidth + $rowsMargin);
        font-weight: bold;
        letter-spacing: -0.007rem;
    }

    &__content {
        display: flex;
        border-top: 0.2rem solid $gray;
    }

    &__title {
        min-width: $titleWidth;
        width: $titleWidth;
        height: auto;
        min-height: 6rem;
        padding: 0.5rem 1.4rem;
        font-weight: bold;
        
        &.blue {
            background-color: rgba($settings-basal-programs, 0.1);
        }
        &.yellow {
            background-color: rgba($settings-bolus-target, 0.1);
        }
        &.green {
            background-color: rgba($settings-bolus-insulin, 0.1);
        }
        &.violet {
            background-color: rgba($settings-bolus-factor, 0.1);
        }
        &.pink {
            background-color: rgba($settings-bolus-duration, 0.1);
        }
    }

    &__rows {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: $rowsMargin;
    }

    &__row {
        display: flex;
        height: 3rem;
        padding-top: 0.5rem;
        font-size: 1.4rem;
        font-weight: 600;
        border-bottom: 0.1rem solid rgb(187, 189, 205);

        &.last {
            border: none;
        }
    }

    &__td {
        padding-right: 0.5rem;
        &.small {
            width: 14rem;
        }
    }
}

@media print {
    .settings-table {
        &__title {
            -webkit-print-color-adjust: exact;
        }
    }
}
