.patientInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__wrap {
    width: 100%;
  }

  &__name {
    max-width: 90%;
    padding: 1.5rem 0 0;
    font-family: $alternative-font;
    font-size: 4.2rem;
    font-weight: bold;
    white-space: nowrap;
    letter-spacing: -0.042rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__info {
    margin-bottom: 1rem;
    font-weight: 600;
    letter-spacing: -0.007rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subtitle {
    height: 2.8rem;
    font-weight: bold;
    line-height: 2.8rem;
    font-size: 2rem;
  }

  &__logo {
    min-width: 22rem;
    padding: 2rem 0 0;
    text-align: right;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    display: flex; 
  }

  &__agp-trademark {
    margin-bottom: 3.5%;
    font-size: 1rem;
    color: #737373;
    text-align: right;
    vertical-align: bottom;
  }

  &__agp-trademark-compress {
    max-width: 22rem;
  }

  &__settings-title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.8rem;
  }

  &__no-sufficient-data-to-show-summary {
    color: $red;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
  }
} 

// print
@media print {
  .patientInfo {
    page-break-before: always;
  }
}
