.upload {

    &__inputs-container {
        display: flex;
        flex-wrap: wrap;
        width: min-content;
        
        input {
            width: 20rem;
            height: 3.5rem;
            margin: 0 1rem;
            margin-bottom: 3rem;
            border-radius: .3rem;
            border-color: lightgray;
        }
    
        textarea {
            width: 52rem;
            height: 10rem;
            margin: 0 1rem;
            margin-bottom: 3rem;
            border-radius: .3rem;
            font-family: OpenSans;
            border-color: lightgray;
        }
    }

    &__input {
        width: 50%;

        @include lg-down {
            width: 100%;
        }
    }

    .modal__iconWarning {
        margin-left: auto;
        margin-right: auto;
    }

    &__comments {
        width: 100%;
    }

    &__buttons {
        margin-top: 3rem;

        button {
            margin-right: 2rem;
        }
    }

    &__datetime {
        > div {
            border-radius: 1rem;
        }
    }
}