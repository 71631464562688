@import './variables';

.timezone-change-notification {
    width: 9.1rem;
    height: 4.4rem;
    padding: 0.3rem 1.5rem;
    border-radius: 0.4rem;
    text-align: center;
    background-color: $lightGrey;

    &__icon {
        height: 45%;

        svg {
            color: $main-btn-bg-warn;
        }
    }

    &__notification {
        color: $black;
        font-size: 0.8rem;
        font-weight: 600;
        line-height: 1rem;
    }
}