.main-content {
    width: 100%;
    padding: 3rem 6.4rem 5.6rem 3.2rem;
    box-shadow: inset 0.2rem 0 0.4rem 0 rgba(0,0,0,0.2);


    &__title {
        margin-bottom: 0.4rem;
        font-family: $alternative-font-with-fallback;
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: -0.1rem;
    }

    &__description {
        margin-bottom: 2.4rem;
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: -0.043rem;
        color: $content-descr-color;
    }
}
