.pie-chart-percent {
    text-anchor: middle;
    font-size: 1rem;
    font-weight: bold;
}

.pie-chart-descr {
    text-anchor: middle;
    font-size: 0.7rem;
    font-weight: 600;
}