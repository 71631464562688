.info-panel {
    width: 935px;
    height: 69px;
    display: flex;
    flex-flow: row;
    padding: 0 1.5rem;
    position: relative;
    border-radius: 0.4rem;
    background-color: $lightGrey;

    &__label {
        width: 100%;
        font-size: 1.2rem;
        text-decoration: underline;
    }

    &__item {
        width: 50%;
        display: flex;
        padding: .1rem 0;
        white-space: nowrap;
        flex-flow: nowrap row;
    }

    &__hatch {
        background-image: linear-gradient(0deg, #93bae1 25%, #4b6c8e 25%, #4b6c8e 50%, #93bae1 50%, #93bae1 75%, #4b6c8e 75%, #4b6c8e 100%);
        background-size: 0.8rem 0.8rem;
    }

    &__marker {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.5rem;
        font-size: 1rem;
        color: $white;
        border-radius: 0.3rem;
    }

    &__white-border {
        border: 0.2rem solid $white;
    }

    &__activity {
        margin-bottom: 0.2rem;
    }

    &__item-text {
        font-size: 1.2rem;
        font-weight: 600;
    }

    &__content {
        width: 95%;
        display: flex;
        flex-wrap: wrap;
        margin-top: .2rem;
    }

    .pod-icon {
        margin-right: 0.5rem;
    }
}
