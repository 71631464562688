$table-td-small: 15rem;
$table-td-middle: 20rem;
$table-td-large: 30rem;

.table {
    table {
        width: 100%;
        color: $table-color;

        thead {
            font-size: 1.4rem;
            color: $table-header-color;
            tr {
                display: flex;
                padding: 1rem 0;
                border-bottom: 0.2rem solid $table-head-border;

                td {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 1rem;

                    &.right {
                        justify-content: flex-end;
                    }

                    &.center {
                        justify-content: center;
                    }

                    &.small {
                        width: $table-td-small;
                    }
                    &.middle {
                        width: $table-td-middle;
                    }
                    &.large {
                        width: $table-td-large;
                    }
                }
            }
        }

        tbody {
            font-size: 1.5rem;
            font-weight: 600;
            letter-spacing: -0.036rem;
            tr {
                display: flex;
                position: relative;
                padding: 1rem 0;
                border-bottom: 0.2rem solid $table-border;

                td {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 1rem;
                    
                    &:first-child {
                        font-weight: bold;
                    }

                    &.right {
                        justify-content: flex-end;
                    }

                    &.center {
                        justify-content: center;
                    }

                    &.small {
                        width: $table-td-small;
                    }
                    &.middle {
                        width: $table-td-middle;
                    }
                    &.large {
                        width: $table-td-large;
                    }
                }

                &.withIcon,
                &.editPatients {
                    padding: 0;
                }

                &.editPatients {
                    .table__changeRowWrapper {
                        padding: 0;
                    }
                }

                &.active {
                    background-color: $table-row-bg-active;
                }
            }
        }
    }
}

.tableEmptyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;

    img {
        margin-bottom: 2rem;
    }

    &__title {
        margin-bottom: 1rem;
        font-family: $alternative-font-with-fallback;
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: -0.1rem;
        color: $table-empty-color;
    }

    &__descr {
        margin-bottom: 3rem;
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: -0.043rem;
        color: $table-empty-color;
    }
}
