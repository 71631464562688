.sidebar {
    min-width: 25.6rem;
    background-color: $white;
    &__logo {
        padding-top: 4.6rem;
        padding-left: 2.3rem;
        padding-bottom: 5rem;
    } 
    
    &__list {
        margin-bottom: 3rem;

        &.last {
            position: relative;
            padding-top: 3rem;

            &:before {
                content: '';
                display: block;
                width: 21.6rem;
                height: 0.1rem;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: $input-border;
            }
        }
    }

    &__list-title {
        color: $sidebar-title-color;
        text-transform: uppercase;
        margin-bottom: 2rem;
        padding-left: 2.3rem;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1.6rem;
    }

    &__list-item {
        position: relative;
        padding: 1rem 5rem;
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: -0.043rem;
        color: $sidebar-list-color;
        cursor: pointer;
        transition: .1s;

        &.active {
            background-color: $sidebar-list-bg-active;
            font-weight: bold;
            color: $black;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 0.5rem;
                background-color: $sidebar-active-line-bg;
            }
        }

        &:hover {
            background-color: $sidebar-list-bg-active;
            transition: .1s;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
            cursor: default;
        }
    }

    &__non-production-label {
        height: 2.9rem;
        text-align: center;
        background-color: rgba($main-btn-bg-warn, .25);
        
        > div {
            padding: 0.25rem 0;
            font-size: 1.4rem;
            font-weight: bold;
            color: $main-btn-bg-warn;
        }
    }
}
