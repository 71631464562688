
.agreement-wrapper {
    .modal-wrapper {
        background-color: $agr-modal-wrapper-bg;
    }
}

.agreement {
    &__description {
        background-color: $agreement-background-color;
        border-radius: 0.4rem;
        padding: 3rem;
        height: 35rem;
        width: 60rem;
        overflow: hidden;
        position: relative;
    }

    &__iframe {
        height: 29rem;
        width: 54rem;
        overflow: scroll;
        border: none;
    }

    &__gradient {
        position: absolute;
        width: 54rem;
        height: 5rem;
        bottom: 3rem;
        background: linear-gradient(rgba(247, 247, 247, 0), $agreement-background-color);

    }

    &__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60rem;
    }

    &__action-button {
        min-width: 10.4rem;
        height: 4rem;
        padding: 1rem 2rem;
        border: none;
    }


    &__check-box {
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        position: relative;
        margin: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
        .MuiCheckbox-colorPrimary.Mui-checked {
            color: $radio-bg-active;
        }
        .MuiIconButton-root {
            padding: 0 1rem;
        }
    }
}