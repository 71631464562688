// colors
$black: #000000;
$white: #ffffff;
$red: #f75e4c;
$veryLow: #952826;
$low: #e66855;
$target: #52b762;
$high: #f3a93c;
$veryHigh: #e48c3a;
$lightGrey: #ececec;
$gray: #bbbdcd;
$darkGray: #7a7a7a;
$darkGrayExtended: #c4c4c4;
$lightBlue: #def1ff;
$link-color: #1977de;

// fonts
$main-font: OpenSans;
$alternative-font: IBMPlexSans;
$main-font-with-fallback: OpenSans, sans-serif;
$alternative-font-with-fallback: IBMPlexSans, sans-serif;

// sidebar
$sidebar-title-color: rgba($black, 0.4);
$sidebar-list-color: rgba($black, 0.6);
$sidebar-list-bg-active: #f6f6f6;
$sidebar-active-line-bg: #5b34b9;

// footer
$footer-bg: #F1EFE9;
$footer-descr-color: #1b1a1a;
$footer-border-bg: #a9a8a8;

// about
$about-block-bg: #f6f6f6;

// main-content
$content-descr-color: #434343;

// buttons
$main-btn-bg: #5b34b9;
$main-btn-bg-disable: #f5f5f5;
$main-btn-color-disable: #9b9b9b;
$main-btn-bg-warn: $red;
$main-btn-border-disable: #f4f4f4;

// inputs
$input-color: #343434;
$input-border: #e6e6e6;

// radios
$radio-border: rgba($black, 0.3);
$radio-bg-active: #5b34b9;

// selects
$select-color: #121212;
$select-hover-bg: #f6f6f6;

// table
$table-header-color: #343434;
$table-color: #151515;
$table-head-border: #dcdee5;
$table-border: #f3f3f3;
$table-row-bg-active: #f6f6f6;
$table-empty-color: #8e8e93;

$agreement-background-color: #f7f7f7;

// error pages
$recall-color: #8e8e93;

// modal
$modal-subtitle-color: #343434;

// report settings
$settings-basal-programs: #006fdd;
$settings-bolus-target: #ffa700;
$settings-bolus-insulin: #2bda7b;
$settings-bolus-factor: #5b34b9;
$settings-bolus-duration: #f75e4c;

// agreement
$agr-modal-wrapper-bg: #afafaf;

// insulin bolus
$insulin-bolus-meal: #91c4c5;
$insulin-bolus-meal-color: #739d9e;
$insulin-bolus-correction: #eaccec;
$insulin-bolus-correction-dark: #c49cd4;
$insulin-bolus-correction-color: #a376b4;
$insulin-bolus-manual: #feb58d;
$insulin-bolus-manual-color: #a9775c;

// breakpoints
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
