.footer {
    padding: 5.6rem 0 2.6rem;
    border-top-left-radius: 10rem;
    background-color: $footer-bg;
    z-index: 1;

    &__container {
        margin: 0 16.5rem 0 23rem;
    }

    &__content {
        display: flex;
        margin-bottom: 3.3rem;
    }

    &__logo-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 8.3rem;
    }

    &__logo {
        img {
            width: 12rem;
        }
    }

    &__soc-net {
        display: flex;
        a {
            width: 2.6rem;
            height: 2.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1.1rem;
            background-color: $white;
            border-radius: 50%;
            opacity: 1;
            transition: 0.1s;

            img {
                width: 1.5rem;
            }

            &:hover {
                opacity: 0.7;
                transition: 0.1s;
            }
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        width: 16.4rem;
        margin-right: 12.1rem;
        margin-bottom: 1.2rem;
        font-size: 1.2rem;

        a {
            color: $footer-descr-color;
            text-decoration: underline;
        }

        &:hover {
            a {
                text-decoration: none;
            }
        }
    }

    &__descr {
        border-top: 0.1rem solid $footer-border-bg;
        padding-top: 2.7rem;
        font-size: 1.2rem;
        color: $footer-descr-color;
    }
}