.report-summary {
    &__header {
        margin-bottom: 1rem;
    }

    &__time-range {
        margin-bottom: 2rem;
    }

    &__glucose-metrics {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
}