.resources {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 3rem -1.6rem;
    }

    &__help {
        display: flex;
        flex-direction: column;
    }

    &__block {
        width: calc(50% - 3.2rem);
        margin: 0 1.6rem 3rem;
        padding: 2rem 2rem 1.9rem;
        background-color: $about-block-bg;
        border-radius: 2rem;
    }

    &__title {
        margin-bottom: 1.2rem;
        letter-spacing: -0.1rem;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__descr {
        margin-top: 1.2rem;
        font-size: 1.4rem;
    }

    &__descr_large {
        margin-top: 1.2rem;
        font-size: 1.5rem;
        font-weight: 600;
    }

    &__link {
        margin-top: 1.7rem;
        font-size: 1.5rem;
        font-weight: 600;
        color: $link-color;
        text-decoration: underline;
        cursor: pointer;
    }

    &__black {
        color: $black;
    }

    &__margin-top {
        margin-top: 1rem;
    }

    &__col {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
    }

    &__row {
        display: flex;
        flex-direction: row;
    }

    &__row-build-date {
        display: flex;
        flex-direction: row;
        margin: 1.5rem 0;
    }

    &__contact-descr {
        font-size: 1.4rem;
    }

    &__col-ref {
        display: flex;
        flex-direction: column;
        margin-right: 1.2rem;
        margin-top: 0.5rem;
    }
}
