.settings {
    &__header {
        margin-bottom: 1rem;
    }

    &__title {
        margin-bottom: 1rem;
    }

    &__main-block {
        margin-bottom: 1rem;
    }

    &__basal-subtitle {
        padding: 0.3rem 0 0.5rem;
        font-weight: bold;
        letter-spacing: -0.007rem;
        line-height: 1.8rem;
        border-top: 0.2rem solid rgb(187, 189, 205);
    }

    &__table {
        margin-bottom: 0.7rem;
    }

    &__bolus-bottom-row {
        display: flex;
        flex-direction: row;
    }

    &__bolus-bottom-title {
        width: 24%;
    }

    &__padding-bottom {
        padding-bottom: 1rem;
    }

    &__training-link {
        margin: 1rem 0;
        color: $black;
        font-size: 1.2rem;
        font-weight: 600;
        a {
            color: $black;
        }
    }
}

@media print {
    .settings__insulin-duration-title-container {
        -webkit-print-color-adjust: exact;
    }
}
