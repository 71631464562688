.home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: $main-font-with-fallback;

    &__main-content {
        display: flex;
        flex-grow: 1;
    }

    &__banner {
        height: 2.9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $main-btn-bg-warn;

        > div {
            color: $white;
            font-size: 1.4rem;
        }
    }
}