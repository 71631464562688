.patient-details {
    font-family: $main-font;
}

.display-on-print-only {
    display: none;
}

@media print {
    .patient-details {
        display: block;
    }
}
