
.report-daily {
    &__header {
        margin-bottom: 1rem;
    }

    &__chart {
        margin-top: 1.5rem;
        height: 29rem;
    }

    &__legend {
        display: flex;
        justify-content: space-between;
    }

    &__legend-title {
        font-size: 2rem;
        font-weight: bold;
    }
}