.login {
    display: flex;
    margin-top: 10%;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &__input-container {
        margin-top: 3rem;
        text-align: center;

        input {
            width: 20rem;
            height: 3.5rem;
            margin: 0 2rem;
            margin-bottom: 3rem;
            border-radius: .3rem;
            border-color: lightgray;
        }
    }

    &__container {
        height: 30rem;
        padding: 3rem;
        border: 1px solid;
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0rem .3rem .8rem;
    }

    &__title {
        text-align: center;
    }

    .modal {
        width: 65rem;
        font-family: Opensans;
    }
    
    .modal__iconWarning {
        margin-left: auto;
        margin-right: auto;
    }

    &__loading {
        text-align: center;

        > div {
            margin-right: 2rem;
        }
    }

    &__buttons {
        margin-top: 3rem;

        button {
            margin: 0 2rem;
        }
    }
}
