@import './mixins';
@import './variables';
@import './fonts';
@import './input';
@import './select';
@import './button';
@import './radio';
@import './checkbox';
@import './table';
@import './home';
@import './footer';
@import './sidebar';
@import './main-content';
@import './patient-details';
@import './report-daily';
@import './report-summary';
@import './patient-top-info';
@import './report-title';
@import './time-range';
@import './insulin-statistics';
@import './metrics-list';
@import './daily-chart';
@import './info-panel';
@import './glucose-chart';
@import './resources';
@import './patient-directory';
@import './modal';
@import './timezone-change-notification';
@import './agreement';
@import './error-message';
@import './spinner';
@import './settings';
@import './block-withBg';
@import './settings-table';
@import './pie-chart';
@import './upload';
@import './login';

html {
  font-size: 10px;
}

body {
    font-size: 1.6rem;
    margin: 0;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
}

div {
    box-sizing: border-box;
}

.container {
    @include containerWidth;
}

.bold {
    font-weight: bold;
}
