@import './variables';

$empty-message-top: 100px;
$glucose-chart-bolus-top: -385px;
$glucose-chart-bolus-margin-bottom: -200px;
$glucose-chart-fon-top: 65px;
$glucose-chart-fon-left: 3px;
$glucose-chart-fon-height: 534px;
$glucose-chart-fon-width: 908px;


.glucose-chart {
    overflow: hidden;
    position: relative;

    &__chart-wrap {
        position: relative;
    }

    &__empty-message {
        position: absolute;
        top: $empty-message-top;
        left: 50%;
        padding: 15px;
        background-color: $lightGrey;
        color: #737373;
        border-radius: 4px;
        font-style: italic;
        font-weight: bold;
        transform: translateX(-50%);
        z-index: 1;
    }

    .chartMeasure {
        position: relative;
        fill: rgb(115, 115, 115);
        font-size: 12px;
        font-weight: 600;
    }

    .axis {
        path, line {
            fill: none;
            stroke: var(--grey);
            shape-rendering: crispEdges;
        }

        text {
            fill: $black;
        }

        .tick line {
            stroke: rgba($gray, 0.2);
        }
    }

    .xAxis {
        text {
            display: none;
        }
    }

    .tick-label {
        fill: $black;
        font-size: 10px;
    }

    .area {
        stroke-width: 1;
        opacity: 0.9;
        &.outer {
            fill: url(#glucose-gradient-bottom);
        }

        &.lower.outer {
            fill: url(#glucose-gradient-bottom);
        }

        &.inner {
            fill: url(#glucose-gradient-top);
            stroke: url(#glucose-gradient-top);
            stroke-width: 0;
        }

        &.lower.outer,
        &.upper.outer {
            stroke-dasharray: 6;
        }
    }

    .target-range-label-block {
        font-size: 16px;
        font-weight: bold;
        height: 36px;
        letter-spacing: -0.07px;
        line-height: 18px;
        text-transform: capitalize;
        text-anchor: end;
    }

    .median-line {
        fill: none;
        stroke-width: 3;
    }

    .x.axis text {
        font-size: 10px;
        stroke: $black;
    }
    
    .noAxis {
        path, line {
            fill: none;
            stroke: none;
        }
    }

    .yAxis {
        path, line {
            fill: none;
            stroke: rgba($gray, 0.2);
            shape-rendering: crispEdges;
        }

        text {
            font-size: 12px;
            font-weight: 600;
            fill: rgb(115, 115, 115);
        }

        .tick line {
            stroke: rgba($black, 0.1);
        }

        &:last-child {
            line {
                stroke-width: 1.5px;
                opacity: 0.6;
                stroke: $black;
            }
        }
    }

    .stats-card {
        height: 50px;
        fill: $lightBlue;
    }

    .card-title {
        font-size: 12px;
    }

    .card-title-bottom {
        font-size: 14px;
        font-weight: 600;
    }

    .mode-point {
        font-size: 14px;
        font-weight: 600;
    }

    .line-title {
        height: 18px;
        font-weight: 600;
        letter-spacing: -0.07px;
        line-height: 18px;
    }

    .line-settings-title {
        font-size: 20px;
    }

    .card-subtitle {
        font-weight: bold;
    }

    .stats-card__chart {
        margin-top: 30px;
        position: relative;
        top: $glucose-chart-bolus-top;
        margin-bottom: $glucose-chart-bolus-margin-bottom;
    }

    .label-title {
        height: 20px;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: -0.36px;
    }

    .label-subtitle {
        height: 14px;
        font-size: 10px;
        line-height: 14px;
    }

    .glucose-fon {
        position: absolute;
        top: $glucose-chart-fon-top;
        left: $glucose-chart-fon-left;
        width: $glucose-chart-fon-width;
        height: $glucose-chart-fon-height;
        z-index: -1;
        margin-left: 33px;
    }
}