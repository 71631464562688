.checkbox {
    label {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        background-color: $white;
        border: 0.1rem solid $radio-border;

        &::before {
            content: '';
            display: none;
            width: 1.8rem;
            height: 1.8rem;
            background-color: $radio-bg-active;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0.5rem;
            display: none;
            width: 1rem;
            height: 0.5rem;
            border-left: 0.2rem solid $white;
            border-bottom: 0.2rem solid $white;
            transform: rotate(-45deg);
        }
    }

    input {
        display: none;
    }
}

.checkbox input:checked + label::before,
.checkbox label.active::before,
.checkbox label.active::after {
    display: block;
}