.input {
    position: relative;
    padding: 0.9rem 0.9rem 1.1rem;
    background-color: $white;
    border: 0.1rem solid $input-border;
    border-radius: 0.8rem;

    input {
        width: 100%;
        height: 100%;
        padding: 0;
        border: none;
        outline-style: none;
        box-sizing: border-box;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: -0.036rem;
        color: $input-color;
    }

    &__icon {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 5rem;
    }

    &.withIcon {
        padding-left: 5rem;
    } 
}