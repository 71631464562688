@mixin containerWidth {
    max-width: 110rem;
    margin: 0 auto;
    padding: 0 1.5rem;
}

@mixin md-down {
    @media (max-width: #{$screen-md}) {
        @content;
    }
}

@mixin md-up {
    @media (min-width: #{$screen-md}) {
        @content;
    }
}

@mixin lg-down {
    @media (max-width: #{$screen-lg}) {
        @content;
    }
}

@mixin lg-up {
    @media (min-width: #{$screen-lg}) {
        @content;
    }
}

@mixin xl-down {
    @media (max-width: #{$screen-xl}) {
        @content;
    }
}

@mixin xl-up {
    @media (min-width: #{$screen-xl}) {
        @content;
    }
}
