.block-withBg {
    padding: 0.5rem 1.4rem;
    border-radius: 0.4rem;
    background-color: $lightGrey;
    font-weight: 600;
}

@media print {
    .block-withBg {
        -webkit-print-color-adjust: exact;
    }
}
