@font-face {
    font-family : IBMPlexSans;
    src         : url(/assets/fonts/IBMPlexSans-Bold.woff2) format("woff2"), url(/assets/fonts/IBMPlexSans-Bold.ttf) format("truetype");
    font-weight : bold;
    font-style  : normal;
    font-stretch: normal;
}

@font-face {
    font-family : OpenSans;
    src         : url(/assets/fonts/OpenSans-Regular.woff2) format("woff2"), url(/assets/fonts/OpenSans-Regular.ttf) format("truetype");
    font-weight : normal;
    font-style  : normal;
    font-stretch: normal;
}

@font-face {
    font-family : OpenSans;
    src         : url(/assets/fonts/OpenSans-SemiBold.woff2) format("woff2"), url(/assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
    font-weight : 600;
    font-style  : normal;
    font-stretch: normal;
}

@font-face {
    font-family : OpenSans;
    src         : url(/assets/fonts/OpenSans-Bold.woff2) format("woff2"), url(/assets/fonts/OpenSans-Bold.ttf) format("truetype");
    font-weight : bold;
    font-style  : normal;
    font-stretch: normal;
}
