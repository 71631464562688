$pseudolines-mirror-large-height: 0.8rem;
$pseudolines-mirror-small-height: 0.3rem;
$pseudolines-height: 0.5rem;
$pseudolines-bottom-large-height: 0.8rem;
$pseudolines-bottom-small-height: 0.2rem;

.timeRange {
  &__title {
    margin-bottom: 0.5rem;
  }

  &__line {
    width: 100%;
    height: 1.7rem;
    display: flex;
    justify-content: space-between;
    border-radius: 1.5rem;
    overflow: hidden;
  }

  &__pseudoLines {
    width: 100%;
    position: relative;
    height: 0.1rem;
    margin-bottom: 0.7rem;
  
    > div {
      position: absolute;
      height: 0.1rem;

      &::before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0.1rem;
        height: $pseudolines-height;
        background-color: inherit;
      }

      &::before {
        left: 0;
        top: 0.1rem;
      }

      &::after {
        right: 0;
        bottom: 0.1rem;
      }

      &.mirror {
        &::before {
          top: -$pseudolines-height;
        }

        &::after {
          bottom: -$pseudolines-height;
        }
        &.top {
          &::before {
            height: $pseudolines-mirror-small-height;
            top: -$pseudolines-mirror-small-height;
          }

          &::after {
            height: $pseudolines-mirror-large-height;
            bottom: -$pseudolines-mirror-large-height;
          }
        }
        &.bottom {
          &::before {
            height: $pseudolines-bottom-large-height;
            top: -$pseudolines-bottom-large-height;
          }
          &::after {
            height: $pseudolines-bottom-small-height;
            top: 0;
          }
        }
      }
      &.top {
        top: -$pseudolines-mirror-small-height;
        &::before {
          height: $pseudolines-mirror-large-height;
        }
        &::after {
          height: $pseudolines-mirror-small-height;
        }
      }
      &.bottom {
        top: 0.4rem;
        &::before {
          height: $pseudolines-bottom-small-height;
        }
        &::after {
          height: $pseudolines-bottom-large-height;
        }
      }
      &.veryLow {
        background-color: $veryLow;
      }
      &.low {
        background-color: $low;
      }
      &.target {
        background-color: $target;
      }
      &.high {
        background-color: $high;
      }
      &.veryHigh {
        background-color: $veryHigh;
      }
    }
  }

  &__part {
    position: relative;
    margin-right: 0.1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__part_withoutData {
    width: 100%;
    background-color: $gray;
  }

  &__part_veryLow {
    background-color: $veryLow;
    fill: $veryLow;
  }

  &__part_low {
    background-color: $low;
    fill: $low;
  }

  &__part_target {
    background-color: $target;
    fill: $target;
  }

  &__part_high {
    background-color: $high;
    fill: $high;
  }

  &__part_veryHigh {
    background-color: $veryHigh;
    fill: $veryHigh;
  }

  &__line-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    margin-top: 0.7rem;
  }

  &__labels-wrap {
    margin-top: 1rem;
    padding: 0.7rem 1rem 0.6rem;
    background-color: $lightGrey;
    border-radius: 0.4rem;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__label-info {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: -0.036rem;
    line-height: 1.7rem;
  }

  &__total-time {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: -0.036rem;
    line-height: 1.7rem;
  }

  &__goal {
    font-size: 1.2rem;
    letter-spacing: -0.036rem;
    line-height: 1.7rem;
    color: $darkGray;
  }

  &__percent {
    position: relative;
    margin-right: 0.5rem;
    font-family: $alternative-font;
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 3.8rem;
  }

  &__percent_veryLow {
    color: $veryLow;
  }

  &__percent_low {
    color: $veryLow;
  }

  &__percent_target {
    color: $target;
  }

  &__percent_high {
    color: $veryHigh;
  }

  &__percent_veryHigh {
    color: $veryHigh;
  }
}

.line-info {
  margin-bottom: 0.8rem;

  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  &__goal {
    font-size: 1.2rem;
    letter-spacing: -0.036rem;
    line-height: 1.7rem;
    color: $darkGray;
  }

  &__period {
    height: 2rem;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 2rem;
  }

  &__interval {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: -0.036rem;
    line-height: 1.7rem;
  }

  &__time {
    height: 1.7rem;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: -0.036rem;
    line-height: 1.7rem;
  }

  &.second,
  &.fourth {
    position: absolute;
    bottom: 0;
  }

  &.second {
    left: 17%;
  }

  &.fourth {
    right: 17%;
  }
}

// print
@media print {
  .timeRange {
    &__labels-wrap {
      -webkit-print-color-adjust: exact;
    }

    &__part {
      -webkit-print-color-adjust: exact;
    }

    &__pseudoLines {
      > div {
        -webkit-print-color-adjust: exact;
      }
    }
  }
}
