.btn {
    min-width: 18.4rem;
    padding: 1rem 1.3rem 1.1rem;
    border-radius: 0.8rem;
    background-color: $main-btn-bg;
    border: 0.1rem solid $main-btn-bg;
    color: $white;
    font-size: 1.5rem;
    letter-spacing: -0.036rem;
    font-weight: 600;
    cursor: pointer;
    transition: .1s;

    &:hover {
        opacity: .7;
        transition: .1s;
    }

    &_warning {
        background-color: $main-btn-bg-warn;
        border-color: $main-btn-bg-warn;
    }

    &_white {
        background-color: $white;
        border: 0.1rem solid $black;
        color: $black;

        &.btn_disabled {
            background-color: $white;
            border: 0.1rem solid $main-btn-border-disable;
        }
    }

    &_disabled {
        background-color: $main-btn-bg-disable;
        border-color: $main-btn-bg-disable;
        color: $main-btn-color-disable;
        cursor: default;
        pointer-events: none;
    }
}
