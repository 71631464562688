.select-wrapper {
    position: relative;
}

.select {
    position: relative;
    padding: 0.9rem 1.2rem 1.1rem;
    padding-right: 4rem;
    border: 0.1rem solid $input-border;
    border-radius: 0.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.036rem;
    color: $select-color;
    cursor: pointer;

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 4rem;
        height: 100%;
    }

    &__dropdown {
        position: absolute;
        bottom: 0;
        border: 0.1rem solid $input-border;
        border-radius: 0.8rem;
        background-color: $white;
        visibility: hidden;
        opacity: 0;
        transform: translateY(102%);
        z-index: 1;

        &.open {
            visibility: visible;
            opacity: 1;
        }
    }

    &__list-item {
        padding: 0.9rem 1.2rem 1.1rem;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: -0.036rem;
        cursor: pointer;

        &:hover,
        &.active {
            background-color: $select-hover-bg;
            font-weight: 700;
        }

        &.disabled {
            cursor: default;
            opacity: .5;
            pointer-events: none;
            &:hover {
                background-color: inherit;
                font-weight: 600;
            }
        }
    }

    &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: .5;
    }
}