.insulin-usage {
    &__title {
        margin-bottom: 0.9rem;
    }

    &__content {
        display: flex;
    }

    &__pie-chart-wrap {
        min-width: 25rem;
    }

    &__pie-chart-descr {
        margin-bottom: 1.2rem;
        font-weight: 600;
    }

    &__pie-chart {
        text-align: center;
    }

    &__info-wrap {
        width: 100%;
        margin-top: -1.7rem;
    }

    &__info-block {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.8rem 0;
        border-top: 0.2rem solid $gray;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__info-item {
        min-width: 33.33%;

        &_full-width {
            width: 100%;
        }
    }

    &__info-item-indent {
        margin-left: 3.2rem;
    }

    &__info-item-title {
        font-weight: 600;
    }

    &__info-item-value {
        font-size: 2rem;
        font-weight: bold;
        line-height: 2.8rem;
    }

    &__info-item-descr {
        position: relative;
        max-width: max-content;
        margin-bottom: 0.8rem;
        padding-right: 0.5rem;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.4rem;

        &:last-child {
            margin-bottom: 0;
        }

        &_gray {
            font-size: 1.4rem;
            opacity: 0.5;
        }

        &_icon {
            padding-left: 1.5rem;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
            }

            &.icon-meal {
                &:before {
                    background-color: $insulin-bolus-meal;
                }
            }

            &.icon-correction {
                &:before {
                    background: repeating-linear-gradient(
                        -45deg, $insulin-bolus-correction, $insulin-bolus-correction 0.4rem, $insulin-bolus-correction-dark 0.2rem, $insulin-bolus-correction-dark 0.6rem
                    );
                }
            }

            &.icon-manual {
                &:before {
                    background-color: $insulin-bolus-manual;
                }
            }
        }
    }

    &__position {
        position: absolute;
        top:-0.4rem;
        right: 0;
        font-size: 0.7rem;
        opacity: 0.5;
    }

    &__line {
        display: flex;
        width: 100%;
    }

    &__line-segment {
        position: relative;
        margin-right: 0.1rem;
        padding: 0 0.5rem;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 2rem;
        border-radius: 0.3rem;
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }

        &_top {
            margin-bottom: 0.3rem;
            padding: 0;

            &.meal {
                span {
                    padding-left: 1rem;
                    color: $insulin-bolus-meal-color;
                }
            }

            &.correction {
                span {
                    padding: 0 0.8rem 0 0.8rem;
                    color: $insulin-bolus-correction-color;
                }
            }

            &.manual {
                width: max-content !important;
                span {
                    padding-right: 1rem;
                    color: $insulin-bolus-manual-color;
                }
            }
        }

        &_meal {
            background-color: $insulin-bolus-meal;
        }

        &_correction {
            background-color: $insulin-bolus-correction;
            background: repeating-linear-gradient(
                -45deg, $insulin-bolus-correction, $insulin-bolus-correction 0.4rem, $insulin-bolus-correction-dark 0.2rem, $insulin-bolus-correction-dark 0.6rem
            );
        }

        &_manual {
            background-color: $insulin-bolus-manual;
        }

        &_empty {
            width: 100%;
            height: 2rem;
            background-color: $lightGrey;
        }
    }

    &__line-path {
        position: absolute;
        width: 0.1rem;
        height: 1.2rem;
        top: -1.4rem;
        left: 0.4rem;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0.4rem;
            height: 0.1rem;
            background-color: inherit;
        }

        &.meal {
            background-color: $insulin-bolus-meal-color;
        }

        &.correction {
            background-color: $insulin-bolus-correction-color;
            &.left,
            &.right {
                height: 0.1rem;
                top: -0.5rem;
                left: 50%;
                background-color: $insulin-bolus-correction-color;
                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    width: 0.1rem;
                    height: 0.2rem;
                    background-color: inherit;
                }
                &::before {
                    top: -0.2rem;
                    left: 0;
                }
                &::after {
                    top: 0.1rem;
                    right: 0;
                }
            }
            &.left {
                transform: scaleX(-1);
            };

            &.right {
                transform: translateX(-100%);
            };
        }

        &.manual {
            left: auto;
            right: 0.4rem;
            background-color: $insulin-bolus-manual-color;

            &::before {
                left: auto;
                right: 0;
                width: 0.5rem;
            }
        }
    }

    &__bolus-wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;
    }

    &__bolus-wrap-item {
        width: 50%;
    }

    &__description {
        margin-top: 2rem;

        .bottomDescr {
            font-size: 1.2rem;
            font-weight: 600;
            line-height: 1.6rem;

            .bold {
                margin-right: 0.2rem;
            }

            span:not(.bold) {
                margin-right: 0.3rem;
                font-weight: normal;
            }

            &__icon {
                svg {
                    margin-bottom: -0.3rem;
                    border-radius: 0.3rem;
                }

                text {
                    font-family: $main-font;
                    font-weight: bold;
                }
            }
        }
    }
}
