.spinner {
    display: inline-block;
    position: relative;

    &.local {
      width: 70px;
      height: 70px;
    }

    &.full-screen {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
    }

    &.inner-block {
      width: 100%;
      height: 100vh;
    }

    > div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 45px;
        height: 45px;
        margin: 8px;
        border: 5px solid $main-btn-bg;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $main-btn-bg transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}

@keyframes lds-ring {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
