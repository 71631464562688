@import './variables';

$daily-chart-left: -100px;
$daily-bolus-top: -54px;
$daily-carbs-top: -58px;
$daily-mode-top: -66px;

.daily-chart {
    position: relative;
    margin: 0 60px;

    &__bolus-label {
        position: absolute;
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        width: 90px;
        padding-bottom: 3px;
        border-bottom: 1px solid $gray;
    }

    &__cutout {
        stroke-width: 1px;
        stroke: $white;
    }

    &__bolus-chart-text {
        color: $black;
        font-size: 12px;
        font-weight: 600;
    }

    &__timezone-change-notification {
        position: absolute;
        top: 25px;
        left: -58px;
    }

    .line {
        fill: none;
        stroke: $black;
        stroke-width: 1.5;
    }

    .axis {
        path, line {
            fill: none;
            stroke: $gray;
            shape-rendering: crispEdges;
        }

        text {
            fill: $black;
        }

        .tick line {
            stroke: rgba($black, 0.1);
        }
    }

    .axisHidden {
        text {
            display: none;
        }
    }

    .y {
        text {
            fill: $black;
        }
    }

    .main__chart-layer {
        stroke: $target;
        stroke-width: 1px;
    }

    .tick line {
        stroke: $gray;
        stroke-width: 0.5px;
    }

    #bolus-chart {
        position: relative;
        top: $daily-bolus-top;
        left: $daily-chart-left;
    }

    #carbs-chart {
        position: relative;
        top: $daily-carbs-top;
        left: $daily-chart-left;
    }

    #mode-chart {
        position: relative;
        top: $daily-mode-top;
        left: $daily-chart-left;
    }

    .titleOne {
        width: max-content;
        text-transform: uppercase;
        font-size: 10px;
    }

    .titleTwo {
        width: max-content;
        font-size: 8px;
        color: $gray;
        fill: $gray;
    }

    .point-carbs-text {
        font-size: 12px;
        font-weight: 600;
    }

    .mode-point {
        fill: $white;
        font-size: 10px;
    }
}

#daily-chart-bg {
    position: absolute;
    top: 1px;
    left: -65px;
    width: 1087px;
    height: 262px;
    z-index: -1;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
}
