.error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        max-height: 21.6rem;
        margin-bottom: 20rem;
    }

    &__title {
        margin-bottom: 1rem;
        font-size: 2.8rem;
        letter-spacing: -0.1rem;
        color: $recall-color;
        font-family: $alternative-font-with-fallback;
        font-weight: bold;
    }

    &__description {
        max-width: 40rem;
        margin-bottom: 3rem;
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: -0.043rem;
        color: $recall-color;
        text-align: center;
    }
}