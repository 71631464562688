.patientDirectory {
    &__search {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2.3rem;
    }

    &__search-right {
        display: flex;

        > div {
            margin-left: 2rem;
        }
    }
}