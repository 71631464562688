.titleWrap {
  padding-top: 0.4rem;
  border-top: 0.2rem solid $black;

  h3 {
    font-size: 2rem;
    font-weight: bold;
    height: 2.8rem;
    line-height: 2.8rem;
  }

  h3.inline {
    display: inline-block;
  }

  h3.additionTitle {
    display: inline;
    font-weight: normal;
  }

  h4.subtitle {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
  }

  &__noData {
    position: relative;
    padding-left: 2.5rem;
    font-style: italic;
    font-weight: bold;
    line-height: 2.8rem;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0.5rem;
      width: 1.5rem;
      height: 0.2rem;
      background-color: $black;
    }
  }
}
