.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($black, 0.4);
    z-index: 1;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 72rem;
    padding: 5.7rem 11rem 6rem;
    border-radius: 0.8rem;
    background-color: $white;

    &__close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        width: 2.4rem;
        height: 2.4rem;
        cursor: pointer;

        &:hover {
            &::before,
            &::after {
                opacity: .5;
                transition: .1s;
            }
        }

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.2rem;
            height: 2rem;
            background-color: $black;
            border-radius: 0.5rem;
            opacity: 1;
            transition: opacity .1s;
        }
        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__iconWarning {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7.2rem;
        height: 7.2rem;
        margin-bottom: 3rem;
        border-radius: 50%;
        background-color: $main-btn-bg-warn;
        color: $white;
        font-size: 6rem;
        font-weight: bold;
    }

    &__title {
        margin-bottom: 0.5rem;
        font-family: $alternative-font-with-fallback;
        font-size: 2.8rem;
        font-weight: bold;
        letter-spacing: -0.1rem;
    }

    &__subtitle {
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: -0.043rem;
        color: $modal-subtitle-color;
    }

    &__descr-wrapper {
        margin-top: 2.2rem;
        margin-bottom: 3rem;
    } 

    &__descr {
        font-size: 1.8rem;
        font-weight: 600;
        letter-spacing: -0.043rem;
        text-align: center;
    }

    &__description-medium {
        font-size: 1.7rem;
    }

    &__buttons {
        display: flex;

        .btn {
            margin-right: 1.5rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}