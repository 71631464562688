@import './variables';

.metricsList {
    width: calc(50% - 1rem);
    border-top: 0.2rem solid $gray;

    &__wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__item-title {
        font-weight: 600;
    }

    &__item-description {
        font-weight: 500;
        &_grey {
            opacity: .5;
        }
    }

    &__item {
        width: calc(50% - 1.5rem);
        margin-right: 1.5rem;
        padding: 0.3rem 0 1rem;
        border-top: 0.1rem solid $gray;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2rem;

        &:nth-child(2n) {
            margin-left: 1.5rem;
            margin-right: 0;
        }

        &.last {
            width: 100%;
            margin: 0;
        }
    }

    &__title {
        padding: 0.3rem 0 0.5rem;
        font-size: 1.6rem;
        font-weight: bold;
        height: 1.8rem;
        letter-spacing: -0.007rem;
        line-height: 1.8rem;
    }
}
