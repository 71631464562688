.radio {
    label {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        background-color: $white;
        border: 0.1rem solid $radio-border;
        border-radius: 50%;

        &::before {
            content: '';
            display: none;
            width: 1.6rem;
            height: 1.6rem;
            background-color: $radio-bg-active;
            border-radius: 50%;
        }
    }

    input {
        display: none;
    }
}

.radio input:checked + label::before,
.radio label.active::before {
    display: block;
}